@import '../variables.scss';

#modal-overlay {
	transition: all 0.2s;
	opacity: 0;
	position: fixed;
	width: 100%;
	display: flex;
	background: #80808082;
	top: 0;
	left: 0;
	justify-content: center;
	align-items: center;
	height: 100%;
	z-index: -1;
}

#modal-overlay.open {
	z-index: 100;
	opacity: 1;
}

$loaderWidth: 20%;

@keyframes slidein {
	0% {
		left: 0%;
	}

	50% {
		left: 100% - $loaderWidth;
	}
	
	100% {
		left: 0%;
	}
}

#modal-body {
	background: white;
	box-shadow: $boxShadow2;

	#loader-container {
		background: lighten($blue, 60%);
		width: 100%;
		height: 7px;
		overflow: hidden;
	}

	#loader-bar {
		position: relative;
		height: 100%;
		width: $loaderWidth;
		background: lighten($blue, 15%);
		animation: slidein 3s infinite;
		animation-timing-function: ease-in-out;
	}
}

#modal-body-wrapper {
	color: $dark;
	padding: 20px;
	min-width: 300px;
	min-height: 200px;
	display: flex;
	flex-direction: column;
	font-size: 14px;

	.title {
		padding: 10px;
		font-size: 2em;
		margin-bottom: 10px;
	}

	.body {
		flex-grow: 1;
	}

	.buttons {
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
	}
}


