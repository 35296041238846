@import 'variables.scss';

body {
	background: linear-gradient(45deg, $dark, lighten($blue, 10%));
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

button {
	cursor: pointer;
	height: 30px;
    width: 60px;
    box-sizing: border-box;
    background: $yellow;
    border: none;
    margin: 0 10px;
    font-weight: bold;
    color: $blue;
	border-radius: 3px;
	box-shadow: $boxShadow;

	&.primary {
		background: $blue;
		color: white;

		&:hover {
			background: lighten($blue, 10%);
		}
	}

	&[disabled], &[disabled]:hover {
		cursor: default;
		background: gray;
	}
}

.spacer {
	flex-grow: 1;
}

.shadow {
	box-shadow: $boxShadow;
}

#root{
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.App {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	overflow: auto;

	&.logged-in {
		background: $lightGray;
	}
}

.login {
	background: rgba(white, .1);
    height: 170px;
	width: 190px;
	padding: 15px;

	form {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}

	input {
		padding: 10px;
		margin: 0 10px;
		border: none;
		box-sizing: border-box;
	}

	button {
		display: block;
		width: unset;
	}
}

.react-datepicker {
	button {
		box-shadow: none;
	}
}
