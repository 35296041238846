@import '../variables.scss';

.top {
	background: $blue;
	padding: 10px 20px;
	display: flex;
	align-items: center;

	.links {
		margin-left: 20px;
		display: flex;
		align-items: center;

		button {
			border-radius: 0;
			font-weight: normal;
			font-size: 20px;
			width: unset;
			background: none;
			color: white;
			box-shadow: none;
			border-bottom: solid 3px transparent;

			&.active {

				border-bottom: solid 3px $yellow;
			}
		}
	}
}
