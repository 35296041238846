@import '../variables.scss';

#budget {
	width: 100%;
	height: 100%;
	display: flex;
	font-size: 14px;

	& > div {
		flex-grow: 1;
	}

	.entries {
		max-width: 75%;
		display: flex;
		flex-direction: column;

		
		.items {
			flex-grow: 1;
			overflow-y: auto;
			padding-top: 20px;
			padding-bottom: 50px;
		}
	}
	
	.item {
		color: $dark;
		background: $lightBlue;
		margin: 10px;
		padding: 20px;
		border-radius: 5px;
		display: flex;
		
		.date {
			margin-right: 20px;
		}
		
		&.offering {
			background: $lightYellow;
		}
		
		.amount {
			font-weight: bold;
		}

		.description {
			cursor: pointer;
		}
		
		.delete {
			color: transparent;
			cursor: pointer;
			margin-left: 15px;
			font-weight: bold;
		}
		
		&:hover {
			.description {
				text-decoration: underline;
			}

			.delete {
				color: #8b95a3;
			}
		}
		
	}
	
	.new {
		background: white;
		cursor: pointer;
		padding: 15px 20px;
		font-size: 1.1em;
		padding: 10px;
		height: auto;
		margin-top: 15px;
		width: 100%;
		box-sizing: border-box;
		margin: 0px;
		margin-top: 15px;
		color: $blue;
		transition: all 0.2s;

		&:hover {
			background: $yellow;
			box-shadow: $boxShadow2;
		}
	}

	.summary {
		color: $dark;
		padding: 10px;
		font-size: 16px;
		

		.container {
			display: flex;
			flex-direction: column;
			padding: 10px;
			background: white;
			box-shadow: #0000002b 1px 1px 4px 1px;
			

			& > div {
				display: flex;
				margin-bottom: 8px;
			}
		}
	}

	.more-container {
		margin-top: 20px;
		display: flex;
		justify-content: center;
	}

	.more {
		margin: auto;
		width: unset;
	}

}

#modal-body .body {
	font-size: 14px;

	.item-type {
		margin-bottom: 30px;

		label {
			margin: 10px;
		}

		input {
			margin-right: 5px;
		}
	}

	$width: 100px;

	.react-datepicker-wrapper input {
		font-size: 14px;
		padding: 10px;
		box-sizing: border-box;
		width: $width;
	}

	.description {
		display: flex;
		margin: 15px 0;
	}

	.row {
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		& > input {
			padding: 10px;
			font-size: 14px;
			width: $width;
			box-sizing: border-box;
		}

		button {
			background: $lightGray;
			width: auto;
			margin: 0;
		}
	}

	.dollar {
		margin-right: 5px;
		font-weight: bold;
		font-size: 1.2em;
		width: 10px;
	}

	textarea {
		resize: none;
		flex-grow: 1;
		padding: 10px;
		font-size: 14px;
		height: 20px;
		border: solid 1px #dfdfdf;
	}

	.label {
		margin-left: 5px;
	}
	
}
